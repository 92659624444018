import React from "react"

// Libraries
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Seo from "components/seo"
import Container from "components/container"
import BlogPostCard from "components/blog-post-card"

const StyledBlogPost = styled.article`

.blog-post__header {
  padding: 30px 20px;
  text-align: center;
  margin: 0px auto;
  max-width: 1100px;

  ${breakpoint.small`
      padding: 50px 50px 30px 50px;
  `}

  ${breakpoint.medium`
      padding-top: 64px;
      padding-bottom: 44px;
  `}

  .date {
      margin-bottom: 8px;
      color: ${colors.purpleDark};
      font-weight: 700;
  }

  h1 {
      font-size: 32px;
      margin-bottom: 10px;

      ${breakpoint.medium`
          font-size: 42px;
      `}
  }

  .excerpt {
      max-width: 825px;
      margin: 0 auto;

      p {
          font-size: 1em;
          line-height: 1.4em;
          font-style: italic;
          color: ${colors.graySelect};

          ${breakpoint.medium`
              line-height: 1.5em;
          `}
      }
  }
}

.blog-post__featured-image {
  width: 100%;
  max-width: 1100px;
  height: 240px;
  margin: 0px auto 30px;

  ${breakpoint.small`
      height: 400px;
  `}

  ${breakpoint.medium`
      height: 700px;
      margin: 0px auto 40px;
  `}

  ${breakpoint.large`
  margin: 0px auto 50px;
  `}
}

.blog-post__content {
  padding-bottom: 64px;
  margin: 0px auto;
  max-width: 1100px;

  ${breakpoint.medium`
      padding-bottom: 124px;
  `}

  h2 {
    font-size: 24px;
    margin: 20px 0 0 0;

      ${breakpoint.medium`
        font-size: 30px;
        margin: 35px 0 10px 0;
      `}
  }

  h3 {
    margin: 25px 0 10px 0;
  }

  p {
      margin: 0px 0px 1em 0px;
  }

  a {
      color: ${colors.orange};
      overflow-wrap: break-word;
      display: inline-block;

      &:hover,
      &.active {
          color: ${colors.purpleDark};
      }
  }

  .wp-block-columns {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .wp-block-column {
          width: 100%;

          ${breakpoint.small`
              width: calc((100% - 40px) / 2);
          `}  
      }
  }

  table {
      margin-bottom: 40px;
  }

  tbody,
  thead {
      font-size: smaller;
      line-height: 1.5em;
  }

  td {
      padding: 2px;
      line-height: 1.2em;

      ${breakpoint.medium`
          padding: 5px;
      `}
  }

  thead th {
      padding: 10px;
  }

  .table_header {
      font-size: small;

      ${breakpoint.medium`
        font-size: medium;
      `}
  }

  .table-row {
      border-bottom: 1px dashed ${colors.gray};
      padding: 10px;
      font-size: smaller;

      ${breakpoint.medium`
          padding: 20px;
      `}
  }

  .thead-sticky {
      position: sticky;
      top: 0;
      background: ${colors.white};
  }

  ul,
  ol {
      list-style: revert;
      padding: 0px 10px 10px;
      ${breakpoint.medium`
        padding: 0px 40px 10px;
      `}
}

  li {
      list-style: revert;
      margin: 5px 10px 5px 15px;

      ${breakpoint.medium`
        margin: 10px 40px 10px 10px;
      `}
  }

  .orangeButton {
	  background-color: ${colors.orange};
	  color: ${colors.charcoal}!important;
	  width: 196px;
	  height: 45px;
	  display: inline-flex;
	  align-items: center;
	  justify-content: center;
	  padding: 12px 18px;
	  border-radius: 100px;
	  font-size: 14px;
	  line-height: 20px;
	  font-weight: 600;
	  box-sizing: border-box;
	  transition: all .3s ease;

	  &:hover {
		background-color: ${colors.charcoal};
		color: ${colors.white}!important;
	  }
  }
  
  sup {
      font-size: 11px;
      vertical-align: revert;
      font-family: revert;
      top: 0;
      left: 0;
  }

  .read-more__posts {
      columns: unset;
  }
  
  figure {
      width: auto !important;
      text-align: center;
      margin: 30px auto;
      color: ${colors.purpleDark};
      font-size: medium;
      line-height: 1.2em;
  }
  
  figcaption {
      font-size: 14px;
      text-align: center;
      color: ${colors.graySelect};
		  width: 100%;
  		margin: 0 auto;

      ${breakpoint.medium`
        width: 50%;
      `}
  }
  
  img.aligncenter {
    display: block;
    margin: 20px auto 10px;
  }

  .dottedbg {
    position: relative;
    z-index: 1;
    display: block;
    margin: 10px auto 20px;
    width: fit-content;

    ${breakpoint.medium`
      margin: 30px auto 60px;
    `}

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 8%;
      left: 6%;
      background-image: radial-gradient(${colors.grayDark} 0.06em,transparent 0.06em),radial-gradient(${colors.grayDark} 0.06em,transparent 0.06em);
      background-size: 11px 11px !important;
      background-position: 0 0, 17px 17px;
      background-repeat: repeat;
      opacity: 0.3;
      z-index: -1;
      }
  }

  .dottedbg.leftAlign {
    margin: 10px 0 20px;
    ${breakpoint.medium`
      margin: 30px 0 60px;
    `}
  }

  .reference-list {
      color: ${colors.graySelect};
      font-size: medium;
      line-height: 1.5em;
      list-style: number;
      padding-left: 25px;
  }
  
  .mobile-overflow {
      overflow-x: auto;
  
      ${breakpoint.large`
          overflow-x: inherit;
      `}

      .table-row ul, ol {
        margin: 10px 10px 10px 20px;
      }
    
      .table-row li {
        margin: 10px;
      }
  }

  .footerNote {
    color: ${colors.graySelect};
    font-style: italic;
    font-size: 14px; 
  }
}

	
`

const StyledBreadCrumb = styled.section`
  padding: 20px 0 0;
  border-bottom-width: 0;

	p {
		font-size: 15px;
		text-align: left;
		margin-left: 20px;
		width: 300px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
    		
		${breakpoint.small`
			font-size: 16px;
			width: 400px;
        `}

		${breakpoint.medium`
			width: 500px;
        `}

		${breakpoint.large`
			font-size: 16px;
			width: 600px;
        `}

    }

  a {
      color: ${colors.orange};
     	&:hover {
          color: ${colors.purpleDark};
        }

  }
`

const StyledReadMore = styled.section`
  .read-more__header {
    width: 100%;
    padding: 64px 0;
    background-color: ${colors.purpleDark};
    text-align: center;
    color: ${colors.white};

    ${breakpoint.medium`
      padding: 124px 0;
    `}
  }

  .read-more__posts {
    display: flex;
    flex-wrap: wrap;

    .blog-post-card--50 {
      ${breakpoint.small`
        width: 50%;
      `}
    }
  }
  
    .miscData {
  	display: none;
  }

`

const BlogPost = ({ data }) => {
  const post = data.wpPost
  const readMorePosts = data.allWpPost.nodes

  return (
    <React.Fragment>
      <StyledBlogPost data-aos="fade" id={post.slug} itemScope itemType="http://schema.org/Article">

        <StyledBreadCrumb>
          <Container>
            <p>
              {post.categories.nodes[0].slug === "press-release" ? (
                <React.Fragment><Link to="/news/">News</Link> &raquo; {post.title}</React.Fragment>
              )
                : null
              }
              {post.categories.nodes[0].slug === "publication" ? (
                <React.Fragment><Link to="/resources/publications/">Publications</Link> &raquo; {post.title}</React.Fragment>
              )
                : null
              }
              {post.categories.nodes[0].slug === "viewpoint" ? (
                <React.Fragment><Link to="/resources/viewpoints/">Viewpoints</Link> &raquo; {post.title}</React.Fragment>
              )
                : null
              }
            </p>
          </Container>
        </StyledBreadCrumb>

        <div className="blog-post__header">
          <p className="date">{post.date} &bull; {post.categories.nodes[0].name}</p>
          <h1 itemProp="headline">{post.title}</h1>
          <div
            itemProp="description"
            className="excerpt"
            dangerouslySetInnerHTML={{ __html: post.excerpt }}
          >
          </div>
        </div>

        <div className="blog-post__content">
          {post.featuredImage ? (
            <GatsbyImage
              className="blog-post__featured-image"
              image={getImage(post.featuredImage.node.localFile)}
              alt={post.title}
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          ) : null}

          <Container
            itemProp="articleBody"
            dangerouslySetInnerHTML={{ __html: post.content }}
          ></Container>
        </div>
        <div className="miscData">
          <meta itemProp="url" content={process.env.GATSBY_VISBY_URL + '/news/' + post.slug + '/'} />
          {post.publicURL ? (
            <div>
              <meta itemProp="og:image" content={process.env.GATSBY_VISBY_URL + post.publicURL} />
              <meta itemProp="image" content={process.env.GATSBY_VISBY_URL + post.publicURL} />
            </div>
          ) :
            <div>
              <meta itemProp="og:image" content={process.env.GATSBY_VISBY_DEFAULT_IMAGE} />
              <meta itemProp="image" content={process.env.GATSBY_VISBY_DEFAULT_IMAGE} />
            </div>
          }
          <meta itemProp="dateModified" content={post.modifiedGmt} />
          <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
            <meta itemProp="name" content="Visby Medical" />
            <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
              <meta itemProp="url" content={process.env.GATSBY_VISBY_LOGO} />
            </div>
          </div>
          <div itemProp="author" itemScope itemType="https://schema.org/Organization">
            <meta itemProp="name" content="Visby Medical" />
            <meta itemProp="url" content={process.env.GATSBY_VISBY_URL} />
          </div>
        </div>

      </StyledBlogPost>

      <StyledReadMore>
        <div className="read-more__header">
          <Container>
            <h2>Read more articles &amp; insights</h2>
          </Container>
        </div>

        <div className="read-more__posts">
          {readMorePosts.map((post) => (
            <BlogPostCard
              key={post.id}
              className="visible blog-post-card--50"
              image={
                post.featuredImage
                  ? post.featuredImage.node.localFile
                  : false
              }
              link={
                post.customFields.download
                  ? post.customFields.fileToDownload.sourceUrl
                  : "/news/" + post.slug + "/"
              }
              publicURL={
                post.featuredImage
                  ? post.featuredImage.node.localFile.publicURL
                  : false
              }
              metaURL={
                post.customFields.metaImage
                  ? post.customFields.metaImage.localFile.publicURL
                  : false
              }
              download={post.customFields.download}
              date={post.date}
              dateGmt={post.dateGmt}
              modifiedGmt={post.modifiedGmt}
              title={post.title}
              excerpt={post.excerpt}
              categoryName={post.categories.nodes[0].name}
              categorySlug={post.categories.nodes[0].slug}
            />
          ))}
        </div>
      </StyledReadMore>
    </React.Fragment>
  )
}

export const query = graphql`
query BlogPostById(
  $id: String!
) {
  wpPost(id: { eq: $id }) {
      id
      slug
      title
      excerpt
      content
      date(formatString: "MM/DD/YYYY")
      dateGmt
      modifiedGmt
      categories {
        nodes {
          name
          slug
        }
      }
      customFields {
        metaDescription
        metaTitle
        download
        featuredPost
        fileToDownload {
          sourceUrl
        }
			  metaImage {
				  localFile {
				    publicURL
  				}
			  }
      }
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    allWpPost(filter: {id: {ne: $id}}, limit: 4) {
      nodes {
        id
        slug
        title
        excerpt
        date(formatString: "MM/DD/YYYY")
        dateGmt
        modifiedGmt
        categories {
          nodes {
            name
            slug
          }
        }
        customFields {
          download
          featuredPost
          fileToDownload {
            sourceUrl
          }
          metaImage {
            localFile {
              publicURL
            }
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }

`

export default BlogPost


export const Head = ({ data: { wpPost: post } }) => {
  let htmlExcerpt = post.excerpt;
  let textExcerpt = htmlExcerpt.replace(/(<([^>]+)>)/gi, "");
  let defaultImage = post.featuredImage ? post.featuredImage.node.localFile.publicURL : false

  return (
    <Seo
      article
      title={post.customFields.metaTitle || post.title}
      description={post.customFields.metaDescription || textExcerpt}
      datePublished={post.dateGmt}
      dateModified={post.modifiedGmt}
      image={post.customFields.metaImage ? post.customFields.metaImage.localFile.publicURL : defaultImage}
    />
  )
}
