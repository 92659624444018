import React from "react"

// Libraries
import { Link } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import LinkWithArrow from "components/link-with-arrow"
import { ExternalButtonLink } from "components/buttons"

const StyledBlogPostCard = styled.article`
  width: 100%;
  display: none;
  flex-wrap: wrap;
  border-bottom: 1px solid ${colors.charcoal};

  &.visible {
    display: flex;
  }

  .blog-post__content {
    width: 100%;
    padding: 40px;

    ${breakpoint.small`
      width: ${props => (props.featured ? "50%" : "100%")};
      padding: 28px;
      border-right: 1px solid ${colors.charcoal};
      border-bottom: 0;
    `}

    ${breakpoint.medium`
      padding: 50px;
    `}
    
     ${breakpoint.large`
      padding: 60px;
    `}

    ${breakpoint.extraLarge`
      padding: 70px;
    `}

    .date {
      margin-bottom: 16px;
      color: ${colors.purpleDark};
      font-weight: 700;

      ${breakpoint.medium`
        margin-bottom: 24px;
      `}
    }

    .title {
      margin-bottom: 16px;
      font-size: ${props => (props.featured ? "29px" : "24px")};
      line-height: 1.15em;
      font-weight: 700;

      ${breakpoint.small`
        margin-bottom: 24px;
        font-size: ${props => (props.featured ? "29px" : "24px")};
        line-height: 1.2em;
      `}

      ${breakpoint.medium`
        font-size: ${props => (props.featured ? "32px" : "32px")};
      `}

      ${breakpoint.large`
        font-size: ${props => (props.featured ? "34px" : "27px")};
      `}

      ${breakpoint.extraLarge`
        font-size: ${props => (props.featured ? "44px" : "36px")};
      `}       
    }
	
	.title a:hover {
		color: ${colors.orange};
	}

	.excerpt p { 
      line-height: 1.6em; 
	}

    .link {
      margin-top: 24px;
      width: fit-content;

      &.link--download {
        min-width: auto;
        width: 126px;
      }
    }
  }

  .blog-post__image {
    width: 100%;
    padding-bottom: 80%;
    display: ${props => (props.featured ? "block" : "none")};
    border-top: 1px solid ${colors.charcoal};

    ${breakpoint.small`
      width: 50%;
      padding: 0;
      border-top: 0;
    `}
  }
  
  .miscData {
  	display: none;
  }
  
`

const BlogPostCard = props => {
  return (
    <StyledBlogPostCard {...props} id={props.slug} itemScope itemType="http://schema.org/Article">
      <div className="blog-post__content">
        <h3 className="date" data-aos="fade-up">
          {props.date} &bull; {props.categoryName}
        </h3>
        <p itemProp="headline" className="title" data-aos="fade-up">
          <Link to={props.link} style={{display: "inline-block"}}>{props.title}</Link>
        </p>
        <div
          itemProp="description"
          className="excerpt"
          dangerouslySetInnerHTML={{ __html: props.excerpt }}
          data-aos="fade-up"
        ></div>

        {props.download ? (
          <ExternalButtonLink
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
            className="link link--download"
            backgroundcolor="primary"
            data-aos="fade-up"
          >
            Download
          </ExternalButtonLink>
        ) : (
          <LinkWithArrow
            to={props.link}
            className="link"
            text="Read More"
            color={colors.orange}
            data-aos="fade-up"
            tabIndex="-1"
          />
        )}
      </div>
      {props.image ? (
        <GatsbyImage
          image={getImage(props.image)}
          alt={props.title}
          style={{ backgroundSize: "cover", backgroundPosition: "center" }}
          className="blog-post__image"
        />
      ) : null}
      <div className="miscData">
        <meta itemProp="url" content={process.env.GATSBY_VISBY_URL + props.link} />
        {props.metaURL ? (
          <div>
            <meta itemProp="og:image" content={process.env.GATSBY_VISBY_URL + props.metaURL} />
            <meta itemProp="image" content={process.env.GATSBY_VISBY_URL + props.metaURL} />
          </div>
        ) :
          <div>
            <meta itemProp="og:image" content={process.env.GATSBY_VISBY_URL + props.publicURL} />
            <meta itemProp="image" content={process.env.GATSBY_VISBY_URL + props.publicURL} />
          </div>
        }
        <meta itemProp="dateModified" content={props.modifiedGmt} />
        <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
          <meta itemProp="name" content="Visby Medical" />
          <div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
            <meta itemProp="url" content={process.env.GATSBY_VISBY_LOGO} />
          </div>
        </div>
        <div itemProp="author" itemScope itemType="https://schema.org/Organization">
          <meta itemProp="name" content="Visby Medical" />
          <meta itemProp="url" content={process.env.GATSBY_VISBY_URL} />
        </div>
      </div>
    </StyledBlogPostCard>
  )
}
export default BlogPostCard
